<script>
import Icon from "../Icon";
export default {
  name: 'gallery',
  props: {
    countView: {
      type: Number,
      default: 3
    }
  },
  data: () => ({
    contentScroll: null,
    stepCount: 0,
    fullStep: 0,
    cardWidth: 0
  }),
  computed: {
    arrowLeft () {
      if(this.stepCount === 0) { return null }
      return this.$createElement(
          'div',
          {
            staticClass: 'gallery__arrow gallery__arrow--left',
            class: [
              {
                disabled: this.stepCount === 0
              }
            ],
            on: {
              click: () => this.scrollContent('DOWN')
            }
          },
          [
            this.$createElement(
                Icon,
                {
                  props: {
                    fill: '#A2ABBE',
                    xlink: 'sliderArrLeft',
                    viewport: '0 0 12 32'
                  }
                }
            )
          ]
      )
    },
    arrowRight () {
      if(this.stepCount === this.fullStep) { return null }
      return this.$createElement(
          'div',
          {
            staticClass: 'gallery__arrow gallery__arrow--right',
            class: [
              {
                disabled: this.stepCount === this.fullStep
              }
            ],
            on: {
              click: () => this.scrollContent('UP')
            }
          },
          [
              this.$createElement(
                  Icon,
                  {
                    props: {
                      fill: '#A2ABBE',
                      xlink: 'sliderArrRight',
                      viewport: '0 0 12 32'
                    }
                  }
              )
          ]
      )
    },
    carouselView () {
      return this.$slots.default.map(el => ({
        ...el,
        data: {
          ...el.data,
          style: {
            width: `${this.cardWidth}px`,
            flex: `0 0 ${this.cardWidth}px`
          }
        }
      }))
    }
  },
  mounted () {
    this.fullStep = this.$slots.default.length < this.countView ? 0 : this.$slots.default.length - this.countView
    this.$nextTick(() => {
      this.cardWidth = this.$el.clientWidth / this.countView
    })
  },
  methods: {
    scrollContent (step) {
      const contentScroll = this.$refs.content.children[0]
      switch (step){
        case 'UP':
          this.stepCount++
          break
        case 'DOWN':
          this.stepCount--
          break
      }
      contentScroll.style.marginLeft = `${this.stepCount * contentScroll.clientWidth * -1}px`
    }
  },
  render (h) {
    // const cardWidth = this.$el ? this.$el.clientWidth / this.countView : 0
    // const children = this.$slots.default.map(el => ({
    //   ...el,
    //   data: {
    //     ...el.data,
    //     style: {
    //       width: `${cardWidth}px`,
    //       flex: `0 0 ${cardWidth}px`
    //     }
    //   }
    // }))
    return h(
        'section',
        {
          staticClass: 'gallery'
        },
        [
          this.arrowLeft,
          h(
            'div',
              {
                ref: 'content',
                staticClass: 'gallery__content'
              },
              this.carouselView
          ),
          this.arrowRight
        ]
    )
  }
}
</script>
